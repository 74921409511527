import { Box, Container, Grid, LinearProgress, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import MainLayout from "../components/MainLayout";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";

import useFetch from "../components/useFetch";

const imagesLink = [
  {
    id: 1,
    url: "https://drive.google.com/file/d/1dSWSKpqx2FUEVwbYbQK_2MRGv5sEtJNY/preview",
  },
  {
    id: 2,
    url: "https://drive.google.com/file/d/1l3mDpP36dNOJOhEMMCvEAow7fHrXqU2M/preview",
  },
  {
    id: 3,
    url: "https://drive.google.com/file/d/1GB8sm6-Pj9r1_J_KifaDMV6yQLSurs37/preview",
  },
];
function Downloads() {
  const theme = useTheme();
  // const { data, isLoading, isError } = useFetch("/media?populate[0]=image");
  const { data, isLoading, isError } = useFetch(
    "/media?filters[type][$eq]=files&populate[0]=file"
  );
  useEffect(() => {
    console.log(data);
    console.log(isLoading);
  }, [data]);
  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Downloads",
    });
  }, []);

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            fontWeight="bold"
            gutterBottom
            align="center"
            mt={5}
          >
            Our Downloads
          </Typography>

          {<Grid container spacing={3} sx={{ py: "3rem" }}>
            {isLoading ? (
              <LinearProgress />
            ) : isError ? (
              <Typography>An error occurred!</Typography>
            ) : (
              data?.data.length > 0 &&
              data?.data?.map((item: any) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>

                  <Typography variant="h5" gutterBottom>

                    <Link
                      to={
                        item?.attributes?.file?.data?.attributes?.url
                      }
                      target="_blank"
                      style={{
                        color: "inherit",
                      }}
                    >
                      {item?.attributes?.name ? item?.attributes?.name : ""}
                    </Link>
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {item?.attributes?.description ? item?.attributes?.description : ""}
                  </Typography>

                </Grid>
              ))
            )}

          </Grid>}
        </Container>
      </Box>
    </MainLayout>
  );
}

export default Downloads;
