import useSWR from 'swr'


const fetcher = (args: any) => fetch(args).then(res => res.json())



function useFetch (url:string) {
  const { data, error, isLoading } = useSWR(`https://hubiquitous-cms-3bwjwnm3fq-uc.a.run.app/api${url}`, fetcher)
 
  return {
    data,
    isLoading,
    isError: error
  }
}

export default useFetch