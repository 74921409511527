import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import MainLayout from "../components/MainLayout";
import ReactGA from "react-ga4";

import useFetch from "../components/useFetch";

const imagesLink = [
  {
    id: 1,
    url: "https://drive.google.com/file/d/1dSWSKpqx2FUEVwbYbQK_2MRGv5sEtJNY/preview",
  },
  {
    id: 2,
    url: "https://drive.google.com/file/d/1l3mDpP36dNOJOhEMMCvEAow7fHrXqU2M/preview",
  },
  {
    id: 3,
    url: "https://drive.google.com/file/d/1GB8sm6-Pj9r1_J_KifaDMV6yQLSurs37/preview",
  },
];
function Gallery() {
  const theme = useTheme();
  // const { data, isLoading, isError } = useFetch("/media?populate[0]=image");
  const { data, isLoading, isError } = useFetch(
    "/media?filters[type][$eq]=image&populate[0]=file"
  );
  // useEffect(() => {
  //   console.log(data);
  //   console.log(isLoading);
  // }, [data]);
  React.useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Gallery page",
    });
  }, []);

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            fontWeight="bold"
            gutterBottom
            align="center"
            mt={5}
          >
            Our Gallery
          </Typography>

          <Grid container spacing={3} sx={{ py: "3rem" }}>
            {isLoading ? (
              <Typography>Loading...</Typography>
            ) : isError ? (
              <Typography>An error occurred!</Typography>
            ) : (
              data?.data.length > 0 &&
              data?.data?.map((item: any) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{ minHeight: "3rem" }}
                    >
                      {item?.attributes?.name ? item?.attributes?.name : ""}
                    </Typography>
                    <img
                      src={item?.attributes?.file?.data?.attributes?.url}
                      alt={item?.attributes?.name ? item?.attributes?.name : ""}
                      width="100%"
                      // height="315"
                      style={{ aspectRatio: "3/2" }}
                    />
                    {/* <iframe
                      src={item?.attributes?.file?.data?.attributes?.url}
                      width="100%"
                      height="315"
                      allow="autoplay"
                    ></iframe> */}
                  </Box>
                </Grid>
              ))
            )}
            {/* <Grid item md={7}></Grid> */}
          </Grid>
        </Container>
      </Box>
    </MainLayout>
  );
}

export default Gallery;
