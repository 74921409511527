import {
  Box,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import MainLayout from "../components/MainLayout";
import ReactGA from "react-ga4";
import ReactPlayer from "react-player/lazy";
import useFetch from "../components/useFetch";


const VideosLinks = [
  {
    id: 1,
    url: "https://www.youtube.com/embed/ZA4jSpZzZQA?si=SSURnMGQMHtQbJ6T",
  },
  {
    id: 2,
    url: "https://www.youtube.com/embed/RzpKL23v7dg?si=VR6uEPe088m-4FIE",
  },
  {
    id: 3,
    url: "https://drive.google.com/file/d/1T7rr70Y6-YU38bZs0_qwc2ugvaMbsTJ_/preview",
  },
  {
    id: 4,
    url: "https://drive.google.com/file/d/1KsGigIljSm0_lgRq-tKicaxxkz6JQTsl/preview",
  },
  {
    id: 5,
    url: "https://drive.google.com/file/d/1jnQhw6mhOGjL_XlMZiUcYSXwc2mv2Xw3/preview",
  },
  {
    id: 7,
    url: "https://drive.google.com/file/d/1hG5qmI24jzgf70o0-32PziHXACUwUZO-/preview",
  },
  {
    id: 8,
    url: "https://drive.google.com/file/d/1OaRLmfLCuWGcXghMnFDqwW4SI9qv-vcw/preview",
  },
  {
    id: 9,
    url: "https://drive.google.com/file/d/1Mj7bffTwvkBcG-QRy1cyUTY9uQ2giA5P/preview",
  },
  {
    id: 10,
    url: "https://drive.google.com/file/d/1RKxlEWLXEFKPjKsQlt1uXFvvxXTMZmji/preview",
  },
  {
    id: 11,
    url: "https://drive.google.com/file/d/1bflgGd6zvytJgmPnufvHmBBLe489lgIh/preview",
  },
  {
    id: 12,
    url: "https://drive.google.com/file/d/1F4K_75isO9FtY6FzvryaUwJSFajOgSFR/preview",
  },
];

function Videos() {
  const theme = useTheme();
  const { data, isLoading, isError } = useFetch(
    "/media?filters[type][$eq]=video&populate[0]=file"
  );
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Videos page",
    });
  }, []);

  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
        <Typography variant="h4" fontWeight="bold" gutterBottom align="center" mt={5}>
                Our Videos
              </Typography>
          <Grid container spacing={3} sx={{ py: "3rem" }}>
            {VideosLinks.map((item) => (
              <Grid item xs={12} md={6} xl={4} key={item.id}>
                {/* <ReactPlayer
                  url={item.url}
                  width="100%"
                  height="315px"
                  controls
                /> */}
                <iframe src={item.url} width="100%" height="315" allow="autoplay"></iframe>
              </Grid>
            ))}
            
          </Grid>
          
        </Container>
      </Box>
    </MainLayout>
  );
}

export default Videos;
