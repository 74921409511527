import { Box, Card, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainLayout from "../components/MainLayout";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { Link } from "react-router-dom";
import useFetch from "../components/useFetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ReactGA from "react-ga4";
import defaultBlogImage from "../assets/hubiquitousBlog.png";
function Blog() {
  const [latestPosts, setLatestPosts] = useState<any>([]);
  const { data, isLoading, isError } = useFetch("/blog-posts?populate[0]=image");
  useEffect(() => {
    console.log(data)
  }, [data])
  const BlogCards = () => {
    const slug = (title: string) => {
      return title
        ?.toLowerCase()
        ?.replace(/ /g, "-")
        ?.replace(/[^\w-]+/g, "");
    }
    if (isLoading) {
      return <Typography variant="body1">Loading...</Typography>;
    }
    if (isError) {
      return (
        <Typography variant="body1" color="error">
          Error fetching blog posts
        </Typography>
      );
    }
    return (
      <Grid
        container
        spacing={4}
        py={4}
        mb={10}
        display={"flex"}
        justifyContent={{ xs: "center", md: "flex-start" }}
      >
        {data?.data?.map((item: any, index: number) => (
          <Grid
            item
            xs={10}
            sm={6}
            md={4}
            key={item?.id || index}
            display="flex"
          >
            <Card
              component={Link}
              to={`/blog/${item?.id}-${slug(item?.attributes?.title || "")}`}
              sx={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                gap: 1,
                // alignItems: "center",
                flexGrow: 1,
                padding: 1,
                cursor: "pointer",
                textDecoration: "none",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  background: "#D9D9D9",
                  
                }}
              >
                <img
                  src={item?.attributes?.image?.data?.attributes?.url || defaultBlogImage}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    aspectRatio: "4/3", // Add aspect ratio
                    objectFit: "cover",
                  }}
                />
              </Box>

              <Typography
                variant="body2"
                sx={{
                  width: "fit-content",
                  borderRadius: "6.87px",
                  background: "#FAF5E1",
                  padding: "0 8px",
                  fontSize: 10,
                  color: "#505050",
                }}
              >
                {new Date(item?.attributes?.publishedAt).toDateString()}
              </Typography>
              <Typography variant="body1" fontWeight="bold">
                {item?.attributes?.title}
              </Typography>
              <Typography variant="body1" color={"#505050"}>
                {item.postedBy}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Blogs page" });
  }, [])
  const handleSortByDate = (a: any, b: any) => {
    const dateA = new Date(a.attributes.publishedAt);
    const dateB = new Date(b.attributes.publishedAt);
    if (dateA < dateB) return 1;
    else if (dateA > dateB) return -1;
    return 0;
  };
  useEffect(() => {
    if (data?.data) {
      setLatestPosts(data.data.sort(handleSortByDate).slice(0, 3));
    }
  }, [data]);
  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg">
          <Box
            textAlign={"center"}
            pb={4}
            mt={5}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Typography variant="body2" color={"#505050"}>
              BLOG POSTS
            </Typography>
            <Typography variant="h4" fontWeight="bold">
              Read more articles about{" "}
              <span style={{ color: "#FFBA00" }}>HUBiquitous</span>
            </Typography>
          </Box>
          <Container maxWidth="md">
            <Carousel autoPlay interval={3000} showThumbs={false} infiniteLoop>
              {latestPosts?.map((item: any, index: number) => (
                <Grid
                  container
                  component="div"
                  justifyContent="right"
                  alignItems="center"
                  key={index}
                  sx={{
                    width: "100%",
                    // Adjust this value or use a more suitable aspect ratio based on your image
                    aspectRatio: "16 / 9",
                    backgroundImage: `linear-gradient(90deg, #8B8A8A00, #191919 ), url(${item?.attributes?.image?.data?.attributes?.url})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover", // Ensures the image covers the entire container
                    backgroundRepeat: "no-repeat",
                    borderRadius: "20px",
                  }}
                >
                  <Grid item xs={12} md={8}></Grid>
                  <Grid item xs={12} md={4} sx={{ py: "4rem", px: "1rem" }}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{ color: "#FFF", fontWeight: "bold" }}
                    >
                      {item?.attributes?.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ color: "#FFBA00" }}
                    >
                      {new Date(item?.attributes?.publishedAt).toDateString()}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Carousel>
          </Container>

          {/* <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid
              item
              xs={12}
              md={10}
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                position: "relative",
                height: 255,
              }}
            >
             
              <Box
                sx={{ position: "absolute", bottom: 0, left: "45%", zIndex: 1 }}
              >
                <HorizontalRuleIcon
                  sx={{
                    fontSize: "2rem",
                    color: "red ",
                  }}
                />
                <HorizontalRuleIcon
                  sx={{
                    fontSize: "2rem",
                    color: "red ",
                  }}
                />
                <HorizontalRuleIcon
                  sx={{
                    fontSize: "2rem",
                    color: "red ",
                  }}
                />
              </Box>
            </Grid>
          </Grid> */}
          <Typography variant="body1" fontWeight={"bold"} gutterBottom pt={5}>
            More Articles
          </Typography>
          <BlogCards />
        </Container>
      </Box>
    </MainLayout>
  );
}

export default Blog;
